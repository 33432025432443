/* BUTTONS */
.btn {
    line-height: normal;
    font-family: $f-head;
    text-transform: uppercase;
    color: $c-white !important;
    text-decoration: none !important;
    font-size: 1rem;
    padding:  9px 12px;
    border: 1px solid $c-red;
    display: inline-block;
    background-color: rgba($c-red,0) !important;
    transition: all 0.3s ease !important;
    &:hover {
        transition: all 0.3s ease !important;
        background-color: rgba($c-red,1) !important;
        color: $c-white;
    };
}

.btn-inv {
    line-height: normal;
    font-family: $f-head;
    text-transform: uppercase;
    color: $c-black !important;
    text-decoration: none !important;
    font-size: 1.3rem;
    padding:  9px 12px;
    border: 1px solid $c-red;
    display: inline-block;
    background-color: rgba($c-red,0) !important;
    transition: all 0.3s ease !important;
    &:hover {
        transition: all 0.3s ease !important;
        background-color: rgba($c-red,1) !important;
        color: $c-white;
    };
}