/* FOOTER */
.footer {
    padding: 20px 16px;
    display: grid;    
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-items: center;
    @media screen and (min-width: 450px){
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;    
        padding: 50px 16px;   
    }
}

.foot_small_logo {
    text-align: center;
    @media screen and (min-width: 450px){
        text-align: left;
    }
    & img {
        width: 100%;
        max-width: 180px;
    }
}

.foot_big_logo {
    text-align: center;
    & img {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 150px;
    }
}

.foot_social {
    text-align: center;
    @media screen and (min-width: 450px){
        text-align: right;
    }
}