/* FORMS */
.er {
    background-color: red;
    color: #FFF;
    margin-bottom: 25px;
    padding: 20px;
    font-weight: bold;
}
.ch {
    background-color: rgb(9, 152, 30);
    color: #FFF;
    margin-bottom: 25px;
    padding: 20px;
    font-weight: bold;
}
label {
    display: block;
    font-weight: 400;
    color: $c-txt;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 10px;
}

.form__inp {
    background-color: #F2F2F2;
    border-radius: 3px;
    margin-bottom: 10px;
    display: block;
    padding: 12px;
    width: 100%;
    border: none;
    color: $c-black;
    &:focus {
        outline: none;
    }
}

.form__ta {
    border: none;
    width: 100%;
    height: 150px;
    display: block;
    background-color: #F2F2F2;
    border-radius: 3px;
    padding: 12px;
    margin-bottom: 10px;
    color: $c-black;
    &:focus {
        outline: none;
    }
}

input[type="checkbox"].inp_check + label {
    display: inline-block;
    padding: 5px 0px 5px 25px;
   background-image: url('./img/checkOff.svg');
    background-size: 15px 15px;
    color: $c-black;
    background-repeat: no-repeat;
    background-position: left top 8px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 10px;
    width: auto;
  }
  input[type="checkbox"].inp_check + label:last-child {
    margin-right: 0px;
  }
  
  input[type="checkbox"].inp_check:disabled + label {
    opacity: 0.4;
  }
  
  input[type="checkbox"].inp_check:checked + label {
    background-image: url('./img/checkOn.svg');
    display: inline-block;
  }
  
  input[type="checkbox"].inp_check {
    display: none;
  }