/* VARIABLES */

/* FONTS */
$f-txt: 'Roboto', serif;;
$f-head: 'Russo One', sans-serif;


/* COLORS */
$c-white: rgba(255,255,255,1); //#FFFFFF
$c-txt: $c-white;
$c-black: rgba(0,0,0,1); //#000000


$c-grey: rgba(60,60,60,1); //#53575f
$c-grey-light: rgba(90,90,90,1); //#5a5a5a
$c-grey-dark: rgba(60,60,60,1); //#3c3c3c
$c-grey-back: rgba(237,236,235,1); //#EDECEB

$c-red: rgba(255,0,0,1); //#ff0000
$c-blue-light: rgba(83,109,110,1);  //#536D6E

/* border-radius */
$border-radius: 4px;

/* paddings */
$p-basic: 10px;

/* widths */
$w-max: 1440px;



/* widths */
//basic height for button, and inputs
$h-basic: 40px;

/* annimation */
$ct-ease-swift: cubic-bezier(0, 0.1, 0.3, 1);
$ct-ease-ease-out: cubic-bezier(0, 0, 0.3, 1);
$ct-ease-ease-in-out: cubic-bezier(0.1, 0, 0.2, 1);
