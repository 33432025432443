/* HEADERS */

$menuToggle: 750px;

.h__nav__wrap {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 10000;
    background-color: $c-black;
}

.h__wrap {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    position: relative;
    @media screen and (min-width: 650px) {
        padding-top: 20px;
    padding-bottom: 25px;
    }
    @media screen and (min-width: 1400px) {
        padding-top: 35px;
    padding-bottom: 25px;
    }
}
.h__home {
    width: 178px;
    height: 85px;
    @media screen and (max-width: 1040px) {
        width: 120px;
        height: 59px;
    }
}
.h__home img {
    width: 100%;
    height: auto;
    
}
.h__nav {
    flex: 1;
    align-items: center;
    display: flex;
    @media screen and (max-width: $menuToggle) {
        justify-content: flex-end;
    }
}


.h__nav__lang {
      width: 65px;
      text-align: left;
      position: relative;
      a {
         @include trans(0.3s, color);
         color: $c-white;
          font-size: 1.125rem;
          &:hover {
            color: $c-red;
  
            @include trans(0.3s, color);
          }
      }
  }  
.h__nav__lang ul {
    margin: 0;
    padding: 10px 3px 10px 3px;
    list-style-type: none;
    background-color: $c-black;
    display: none;
    position: absolute;
    width: 73px;
    z-index: 31;
}
.h__nav__lang li {
text-align: center;
padding: 10px 10px 10px 10px;
}
.h__nav__lang.showMenu ul {
display: block;
}
.h__nav__lang.showMenu button {
    &::before {
        @include trans();
        transform: rotate(-180deg);
    }
}
.h__nav__lang button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1.25rem;
    color: $c-white;
    position: relative;
    width: 65px;
    text-align: left;
    text-transform: uppercase;
    &::before {
        @include trans();
        content: "";
        position: absolute;
        width: 23px;
        height: 10px;
        right: 0px;
        top: 15px;
        background-image: url('./img/arrow_down_white.svg');
        background-repeat: no-repeat;
        background-size: 23px 10px;
        transform: rotate(0deg);    
    }
}

.h__mainNav {
    margin-right: 80px;
    flex: 1;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1300px) {
        margin-right: 40px;
    }
    @media screen and (max-width: 1040px) {
        margin-right: 20px;
    }
    @media screen and (max-width: $menuToggle) {
        flex: none;
        position: absolute;
        left: 0px;
        top: -100vh;
        width: 100%;
        background-color: rgba(0,0,0,0.95);
        display: block;
        text-align: center;
        z-index: 30;
        padding: 10px 16px 20px 16px;
        @include trans();
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 5px;
            left: 0px;
            bottom: 0px;
        }
        &.showMenu {
            @include trans();
            top: 76px;
        }
    }
}
.h__nav__socme {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 150px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $menuToggle) {
        margin: 28px auto 0px auto;        
    }
}
.h__nav__pageMenu {
    flex: 1;
    display: flex;
    margin: 0;
    padding:0px 20px ;
    list-style-type: none;
    justify-content: center;
    gap: 0px 50px;
    @media screen and (max-width: 1300px) {
        gap: 0px 20px;
    }
    @media screen and (max-width: $menuToggle) {
        display: block;     
    }
}

.h__nav__pageMenu a {
    font-family: $f-head;
    text-transform: uppercase;
    color: $c-txt;
    text-decoration: none;
    font-size: 1.125rem;
    @include trans(); 
    &:hover {
        color: $c-red;
    }
    @media screen and (max-width: 1125px) {
        font-size: 0.8rem;
    }
    @media screen and (max-width: $menuToggle) {
        font-size: 1.375rem;
        display: block;
        position: relative;
        text-align: center;
        padding: 5px 0px;
        &.first {
            padding: 0px 0px 5px 0px;
        }
        &::before {
            content: "";
            position: absolute;
            width: 240px;
            height: 1px;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0px;
        }
    }
}

.h__nav__pageMenu li.active a {
    color: $c-red;
}

.h__nav__pageMenu li a:focus {
    color: $c-red;
}

.h__toggleMenu {
    margin-left: 20px;
    width: 40px;
    height: 40px;
    background-image: url('./img/hamburger.svg');
    background-size: 40px 40px;
    border: 0px;
    display: none;
    @media screen and (max-width: $menuToggle) {
        display: block;
    }    

    &.open {
        background-image: url('./img/hamburger-x.svg');
    }
}

.h___btunToContact, .h___btunToContactMobile {
    
    
    line-height: normal;
    font-family: $f-head;
    text-transform: uppercase;
    color: $c-white;
    font-size: 1.5rem;
    padding:  9px 12px;
    border: 1px solid $c-red;
    display: none;
    @media screen and (min-width: $menuToggle) {
        display: inline-block;
        font-size: 0.9rem;
        padding:  9px 9px;
    }  
    @media screen and (min-width: 1040px) {
        font-size: 1.125rem;
        padding:  19px 28px;
    }   
    background-color: rgba($c-red,0) !important;
    transition: all 0.3s ease !important;
    &:hover {
        transition: all 0.3s ease !important;
        background-color: rgba($c-red,1) !important;
        color: $c-white;
    };
}

.h___btunToContactMobile {
    

    display: inline-block;
    @media screen and (min-width: $menuToggle) {
        display: none;
    }
}