/* HOME */
.topInfo {    
    background-image: url('./img/top_back.jpg');
    background-size: cover;
    background-position: center center;
    padding: 70px 16px 50px 16px;
    margin-bottom: 20px;
    @media screen and (min-width: 890px) {
        padding: 190px 16px 150px 16px;
        margin-bottom: 80px;
    }

}

.topInfo > div {
    max-width: 890px;
    margin: 0px auto;
}

.topInfo h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 2.8rem;
    line-height: 3.8rem;
    @media screen and (min-width: 650px) {
        font-size: 3.25rem;
        line-height: 5rem;
    }
    @media screen and (min-width: 890px) {
        font-size: 4.25rem;
        line-height: 5rem;
    }
}

.topInfo h1 span{
    color: $c-red;
}

.topInfo p {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.25rem;
    line-height: 2rem;
    @media screen and (min-width: 650px) {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
}

.twoboxes {
    margin-bottom: 0px;
    @media screen and (min-width: 650px) {
        margin-bottom: 80px;
    }
}
.twoboxesWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @media screen and (min-width: 650px) {
        flex-wrap: nowrap;
        margin-bottom: 0px;
    }
}

.twoboxesItem {
    flex: none;
    width: 100%;
    @media screen and (min-width: 650px) {
        flex: 1;
        width: auto;
    }
}

.twoboxesItem img {
    max-width: 100%;
    box-sizing: border-box;
}
.twoboxesWrap:nth-child(even) {
    .twoboxesItemImg {
        order: 2
    }
    .twoboxesItemTxt {
        order: 1
    }
    @media screen and (min-width: 650px) {
        .twoboxesItemImg {
            order: 1
        }
        .twoboxesItemTxt {
            order: 2
        }
    }
}

.twoboxesItemTxtInner {
    box-sizing:border-box;
    padding: 0px 0px 20px 0px;
    font-size: 1rem;
    line-height: 1.6rem;
    @media screen and (min-width: 650px) {
        padding: 0px 20px ;       
    }
    
    @media screen and (min-width: 850px) {
        padding: 0px 65px ;
        font-size: 1.125rem;
        line-height: 1.8rem;
    }
}
.twoboxesItemTxtInner a {
    text-decoration: underline;
    font-weight: 700;
    color: $c-red;
    @include trans(0.3s, color);
    &:hover {
        color: $c-white;
        @include trans(0.3s, color);
    }
}

.twoboxesItemTxtInner p {
    margin-bottom: 20px;
}

.twoboxesItemTxtInner h2 {
    text-align: left;
    text-transform: uppercase;
    line-height: normal;  
    font-size: 2rem;
    @media screen and (min-width: 650px) {
        font-size: 2rem;  
          
    }
    & span {
        color: $c-red;
    }
}

.homeForm {
    background-color: $c-black;
    box-shadow: 0px 0px 36px -3px rgba(0,0,0,0.75);
    max-width: 600px;
    width: 100%;
    margin: 0px auto 0px auto;
    border: 0px solid rgba($c-white,0.2);
    padding: 16px 16px;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 650px) {
        padding: 40px 40px;
        border: 4px solid rgba($c-white,0.2);
    }   
    
}
.homeFormWrap {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    @media screen and (min-width: 650px) {
       margin-bottom: 80px;
    }  
    &::before {
        z-index: 0;
        content: "";
        position: absolute;
        background-color: $c-red;
        left: 0px;
        top: 25%;
        width: 100%;
        height: 50%;
    }
}
.contact__content__check {
    margin-bottom: 40px;
}

.homeWhatYouNeed {
    margin-bottom: 40px;
    max-width: 875px !important;
    text-align: center;
    & p {
        margin-bottom: 20px;
    }
    & strong {
        font-weight: 700;
        color: $c-red;
    }
    & h2 {
        text-align: center;
        text-transform: uppercase;
        line-height: normal;  
        font-size: 2rem;    
        & span {
            color: $c-red;
        }
    }
}
.homeWhatYouNeedGrid {
        width: 100%;
        max-width: 647px;
        margin: 0px auto;
        display: grid;    
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 40px;
    @media screen and (min-width: 450px){
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;       
    }
}

.homeWhatYouNeedGrid_item {
    text-align: center;
    & img {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        border-radius: 50%;
        border: 3px solid $c-red;
        margin-bottom: 16px;
    }
    
}

.homeFaq {
    background-color: $c-red;
    padding-top: 80px;
    padding-bottom: 130px;
}
.benny {
    width: 100%;
    max-width: 740px;
    margin: -150px auto 40px auto ;
    padding-left: 16px;
    padding-right: 16px;
    & img {
        width: 100%;
        max-width: 318px;
        display: block;
        border-radius: 50%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }
    & p {
        margin-bottom: 10px;
    }
    & h2 {
        text-align: center;
        text-transform: uppercase;
        line-height: normal;  
        font-size: 2rem;
        @media screen and (min-width: 650px) {
            font-size: 2rem;  
              
        }
        & span {
            color: $c-red;
        }
    }
}

.homeContact {
    background-color: $c-white;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    color: $c-black;
    @media screen and (min-width: 650px) {
        padding-top: 90px;
        padding-bottom: 90px;
          
    }
    & h2 {
        text-align: center;
        text-transform: uppercase;
        line-height: normal;  
        font-size: 2rem;
        @media screen and (min-width: 650px) {
            font-size: 2rem;  
              
        }
        & span {
            color: $c-red;
        }
    }
    & p {
        margin-bottom: 30px;
    }
    & p a {
       color: $c-red;
       text-decoration: underline;
    }
    & article {
        width: 100%;
        max-width: 540px;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: auto;
        margin-right: auto;
    }
}